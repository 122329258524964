<template>
  <v-card class="rounded-lg no-scroll dialog-card pa-0" flat>
    <div class="dialog-card">
      <v-row
        class="ma-0 mb-6 movements-header"
        justify="space-between"
        align="center"
      >
        <div>
          <span class="member-name mr-4">{{ truncatedName }}</span>
          <span class="date-range">{{ formattedDateRange }}</span>
        </div>
        <div class="d-flex align-center">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mr-2 button"
                outlined
                large
                @click="dateMenu = !dateMenu"
              >
                <v-icon size="20" class="mr-1">mdi-calendar-outline</v-icon>
                <span class="button-text">Rango de Fechas</span>
              </v-btn>
            </template>
            <v-date-picker
              v-model="dateRange"
              range
              locale="es"
              no-title
            ></v-date-picker>
          </v-menu>

          <v-menu
            v-model="filterMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2 button"
                outlined
                large
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="20" class="mr-1">mdi-filter-outline</v-icon>
                <span class="button-text">Filtrar</span>
              </v-btn>
            </template>

            <v-card color="white" class="pa-2" width="400">
              <v-row class="ma-0 my-2" justify="space-between" align="center">
                <v-btn class="mr-2" outlined small @click="resetFilters()">
                  <span class="filter-button">Borrar</span>
                </v-btn>

                <span class="mr-2 font-weight-bold">Filtros</span>

                <v-btn
                  class="mr-2"
                  small
                  color="#1b273e"
                  @click="getMemberMovements()"
                >
                  <span class="filter-button white--text">Listo</span>
                </v-btn>
              </v-row>

              <hr />

              <v-row class="py-2">
                <v-col
                  class="py-1 filter-item"
                  cols="12"
                  v-for="category in categories"
                  :key="category"
                >
                  <v-checkbox
                    hide-details
                    class="shrink"
                    dense
                    multiple
                    color="#1b273e"
                    v-model="filters"
                    :label="category"
                    :value="category"
                  >
                    <template v-slot:label>
                      <span class="black--text wc-caption-2">{{
                        category
                      }}</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-card>
          </v-menu>
          <!-- Dialog para  -->
          <v-dialog
            v-model="newMovementDialog"
            class="dialog-card-auto white"
            width="30%"
            @click:outside="resetForm()"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2 button"
                large
                color="#1b273e"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white" size="20" class="mr-1">mdi-plus</v-icon>
                <span class="button-text white--text">Nuevo Movimiento</span>
              </v-btn>
            </template>

            <v-card color="white" class="rounded-sm no-scroll" height="auto">
              <div class="dialog-card-auto pa-8 d-flex flex-column">
                <span class="form-label">Categoría</span>
                <v-row class="d-flex align-end">
                  <v-col cols="10" class="d-flex">
                    <v-select
                      v-model="newMovementForm.category"
                      :items="categories"
                      outlined
                      hide-details
                      dense
                      class="mt-2 self-center"
                    ></v-select>
                  </v-col>
                  <v-col cols="2">
                    <div
                      :style="{
                        backgroundColor: newMovementForm.color,
                        height: '40px',
                        border: '2px solid #31384b',
                        borderRadius: '5px',
                      }"
                    ></div>
                  </v-col>
                </v-row>

                <!-- <v-text-field
                  outlined
                  dense
                  class="mt-2"
                  v-model="newMovementForm.title"
                ></v-text-field> -->

                <section
                  v-if="newMovementForm.category == 'Evento'"
                  class="mt-4"
                >
                  <span class="form-label">Grupo</span>
                  <utility-combo
                    :comboType="'IMEF_GROUP'"
                    :comboList="IMEF_GROUPS"
                  />
                  <!-- {{ groupEditing }} -->
                  <!-- <v-combobox
                    v-model="newMovementForm.organizator"
                    :hide-no-data="!groupSearch"
                    :items="IMEF_GROUPS"
                    :search-input.sync="groupSearch"
                    :filter="filter"
                    class="my-2"
                    elevation="0"
                    hide-selected
                    outlined
                    hide-details
                    dense
                    solo
                    @keyup.enter="addNewGroup"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Nuevo grupo: <strong>{{ groupSearch }}</strong>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:item="{ index, item }">
                      <v-row>
                        <v-col cols="9">
                          <v-text-field
                            @click.stop
                            v-if="groupEditing === item"
                            v-model="groupEditing.text"
                            autofocus
                            flat
                            background-color="transparent"
                            hide-details
                            solo
                          ></v-text-field>
                          <span v-else style="width: 90%">
                            {{ item.text }}
                          </span>
                        </v-col>
                        <v-col
                          cols="3"
                          @click.stop
                          class="d-flex align-center justify-end"
                        >
                          <v-btn icon @click="handleGroupEdit(index, item)">
                            <v-icon>{{
                              groupEditing !== item ? "mdi-pencil" : "mdi-check"
                            }}</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            @click="removeGroup(item)"
                            v-if="groupEditing == item"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                  </v-combobox> -->
                  <!-- <v-select
                    outlined
                    dense
                    class="mt-2"
                    v-model="newMovementForm.organizator"
                    :items="IMEF_GROUPS"
                  /> -->
                  <span class="form-label"> Tipo de evento </span>
                  <v-select
                    outlined
                    dense
                    class="mt-2"
                    v-model="newMovementForm.eventType"
                    :items="IMEF_SERVICES"
                  />
                  <span class="form-label"> Puntos EPC </span>
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    class="mt-2"
                    :min="0"
                    v-model="newMovementForm.points"
                  />

                  <span class="form-label"> CEPLAN </span>
                  <v-text-field
                    outlined
                    dense
                    class="mt-2"
                    v-model="newMovementForm.ceplan"
                  />
                </section>

                <section v-if="newMovementForm.category == 'Consejo Directivo'">
                  <span class="form-label">Grupo</span>
                  <utility-combo
                    :comboType="'IMEF_GROUP'"
                    :comboList="IMEF_GROUPS"
                  />
                  <span class="form-label">Año lectivo</span>
                  <v-text-field
                    outlined
                    dense
                    class="mt-2"
                    v-model="newMovementForm.year"
                  />
                  <span class="form-label">Posición</span>
                  <v-text-field
                    outlined
                    dense
                    class="mt-2"
                    v-model="newMovementForm.position"
                  />
                </section>

                <section v-if="newMovementForm.category == 'Comité Técnico'">
                  <span class="form-label">Grupo</span>
                  <utility-combo
                    :comboType="'IMEF_GROUP'"
                    :comboList="IMEF_GROUPS"
                  />
                  <span class="form-label">Tipo de comité</span>
                  <!-- <v-text-field
                    outlined
                    dense
                    class="mt-2"
                    v-model="newMovementForm.committeeType"
                  /> -->
                  <v-combobox
                    outlined
                    dense
                    :items="IMEF_COMMITTEE"
                    v-model="newMovementForm.committeeType"
                  ></v-combobox>
                  <span class="form-label">Posición</span>
                  <v-text-field
                    outlined
                    dense
                    class="mt-2"
                    v-model="newMovementForm.position"
                  />
                  <span class="form-label">Año</span>
                  <v-text-field
                    outlined
                    dense
                    class="mt-2"
                    v-model="newMovementForm.year"
                  />
                  <span class="form-label">Tema</span>
                  <v-text-field
                    outlined
                    dense
                    class="mt-2"
                    v-model="newMovementForm.topic"
                  />
                  <span class="form-label">Puntos EPC</span>
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    class="mt-2"
                    :min="0"
                    v-model="newMovementForm.points"
                  />
                  <span class="form-label">CEPLAN</span>
                  <v-text-field
                    outlined
                    dense
                    class="mt-2"
                    v-model="newMovementForm.ceplan"
                  />
                </section>

                <section v-if="newMovementForm.category == 'Contribución'">
                  <span class="form-label">Grupo</span>
                  <utility-combo
                    :comboType="'IMEF_GROUP'"
                    :comboList="IMEF_GROUPS"
                  />
                  <span class="form-label">Tipo de contribución</span>
                  <v-text-field
                    outlined
                    dense
                    class="mt-2"
                    v-model="newMovementForm.contributionType"
                  />

                  <span class="form-label">Participación</span>
                  <v-text-field
                    outlined
                    dense
                    class="mt-2"
                    v-model="newMovementForm.participation"
                  />

                  <span class="form-label">Puntos EPC</span>
                  <v-text-field
                    outlined
                    dense
                    class="mt-2"
                    type="number"
                    :min="0"
                    v-model="newMovementForm.points"
                  />

                  <span class="form-label">Medio de publicación</span>
                  <v-text-field
                    outlined
                    dense
                    class="mt-2"
                    v-model="newMovementForm.publicationMedium"
                  />
                </section>

                <span class="form-label">Descripción</span>
                <v-textarea
                  v-model="newMovementForm.description"
                  outlined
                  dense
                  height="112px"
                  class="mt-2"
                ></v-textarea>

                <span class="form-label">Fecha</span>
                <v-menu
                  v-model="newMovementDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="button mt-2"
                      outlined
                      large
                    >
                      <v-row align="center" class="px-2">
                        <v-icon color="grey lighten-1" size="20" class="mr-2"
                          >mdi-calendar-outline</v-icon
                        >
                        <span class="button-text grey--text text--lighten-1">
                          {{
                            newMovementForm.date != ""
                              ? newMovementForm.date
                              : "Selecciona una fecha"
                          }}
                        </span>
                      </v-row>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="newMovementForm.date"
                    locale="es"
                    no-title
                  ></v-date-picker>
                </v-menu>

                <!-- <span class="form-label mt-4">Color</span>

                <v-row
                  class="ma-0 color-outline py-2 px-4 mt-2"
                  justify="center"
                  align="center"
                >
                  <div
                    class="color-activator"
                    :style="`background-color: ${newMovementForm.color}`"
                  ></div>
                </v-row> -->

                <!-- <span class="form-label mt-4">Categoria</span>

                <v-select
                  v-model="newMovementForm.category"
                  :items="categories"
                  outlined
                  dense
                  class="mt-2"
                  @change="setCategoryColor()"
                ></v-select> -->

                <v-btn
                  class="mr-2 mt-4 button white--text mb-4"
                  large
                  color="#1b273e"
                  @click="createMovement()"
                >
                  <span class="button-text white--text">Crear Movimiento</span>
                </v-btn>

                <hr />
              </div>
            </v-card>
          </v-dialog>
        </div>
      </v-row>

      <v-expansion-panels flat>
        <v-expansion-panel
          v-for="(day, i) in groupedMovements"
          :key="i"
          class="mb-2 pa-0 day-card rounded-lg"
        >
          <v-expansion-panel-header color="#f8fafc" class="rounded-lg">
            <v-row class="ma-0" align="center" justify="space-between">
              <div class="day-card-title">
                {{ day.day + " de " + monthName(day.month) + ", " + day.year }}
              </div>
              <div class="d-flex align-center">
                <div
                  v-for="(color, index) in day.colors"
                  :key="index"
                  class="color-bar mr-1"
                  :style="`background-color: ${color}`"
                ></div>
                <v-icon class="mx-1" color="black" size="18"
                  >mdi-clipboard-text</v-icon
                >
                <span class="movements-counter mr-4">
                  {{ day.movements.length }}
                </span>
              </div>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#f8fafc" class="rounded-lg">
            <v-row
              v-for="movement in day.movements"
              :key="movement._id"
              class="ma-0 pa-2 py-3 mt-1 movement-card"
            >
              <div
                class="big-color-bar mr-4"
                :style="`background-color: ${movement.color}`"
              ></div>
              <div class="d-flex flex-column">
                <span class="movement-title">{{ movement.category }}</span>
                <span class="movement-description">{{
                  movement.description
                }}</span>
                <div class="d-flex">
                  <div v-for="(item, key, index) of movement" :key="index">
                    <div v-if="IMEF_MOVEMENTS_DICT[key]" class="mr-4">
                      <span class="movement-subtitle">{{
                        IMEF_MOVEMENTS_DICT[key] + ": "
                      }}</span>
                      <span class="movement-description"> {{ item }} </span>
                    </div>
                  </div>
                </div>
              </div>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
import { IMEF_MOVEMENTS_DICT } from "../../../share/imef";
import UtilityCombo from "../../shared/UtilityCombo.vue";
export default {
  components: {
    UtilityCombo,
  },
  props: {
    member: {
      type: Object,
      required: true,
    },
    selectedMember: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      IMEF_MOVEMENTS_DICT,
      IMEF_GROUPS: [],
      IMEF_SERVICES: [],
      IMEF_COMMITTEE: [],
      startDate: new Date(),
      endDate: new Date(),
      dateMenu: false,
      filterMenu: false,
      newMovementDateMenu: false,
      newMovementDialog: false,
      colorMenu: false,
      settedStartDate: false,
      groupSearch: null,
      groupEditing: null,
      prevGroupEditing: "",
      groupEditingIndex: -1,
      newMovementForm: {
        title: "",
        description: "",
        date: "",
        color: "#000",
        category: "",
        points: "",
        organizator: "",
        ceplan: "",
        eventType: "",
        contributionType: "",
        participation: "",
        publicationMedium: "",
        year: "",
        position: "",
        committeeType: "",
        topic: "",
      },
      categories: [
        "Ingreso",
        "Baja",
        "Cambio de categoría",
        "Reingreso",
        "Cambio de grupo",
        "Fallecimiento",
        "Año de Gracia",
        "Evento",
        "Consejo Directivo",
        "Comité Técnico",
        "Contribución",
      ],
      categoriesColors: {
        Ingreso: "#4CAF50",
        Baja: "#E53935",
        "Cambio de categoría": "#FB8C00",
        Reingreso: "#43A047",
        "Cambio de grupo": "#1E88E5",
        Fallecimiento: "#6D4C41",
        "Año de Gracia": "#D81B60",
        Evento: "#00ACC1",
        "Consejo Directivo": "#3949AB",
        "Comité Técnico": "#8E24AA",
        Contribución: "#FDD835",
      },
      swatches: [],
      movements: [],
      filters: [],
    };
  },
  methods: {
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show"]),
    setCategoryColor(category) {
      this.newMovementForm.color = this.categoriesColors[category];
    },
    formattedDate(date) {
      return date.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "short",
      });
    },

    async $_fetchImefUtilities() {
      try {
        let response = await this.axios({
          method: "GET",
          url: "/utilities/fetch-utilities",
          params: {
            utilityTypes: ["IMEF_GROUP", "IMEF_EVENT", "IMEF_COMMITTEE"],
          },
        });

        if (response.status == 200) {
          const {
            IMEF_GROUP = [],
            IMEF_EVENT = [],
            IMEF_COMMITTEE = [],
          } = response.data.utilityLoad;
          this.IMEF_GROUPS = IMEF_GROUP;
          this.IMEF_SERVICES = IMEF_EVENT;
          this.IMEF_COMMITTEE = IMEF_COMMITTEE;
        }
      } catch (error) {
        console.log(error);
      }
    },

    // async addNewGroup() {
    //   let alreadyExist = [...this.IMEF_GROUPS].some((item) => item.value === this.groupSearch);
    //   if (alreadyExist) return;
    //   try {
    //     let response = await this.axios({
    //       method: "POST",
    //       url: "/utilities/post-utility",
    //       data: {
    //         newItem: this.groupSearch,
    //         type: 'IMEF_GROUP'
    //       }
    //     });
    //     if (response.status == 200) {
    //       this.IMEF_GROUPS = response.data;
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    // async handleGroupEdit(index, item) {
    //   if (!this.groupEditing) {
    //     this.prevGroupEditing = item,
    //     this.groupEditing = item
    //     this.groupEditingIndex = index
    //   } else {
    //       // aqui pongo el axios
    //       // validar si text y value son el mismo
    //       console.log(item)
    //       if (item.text === item.value) return;
    //       try {
    //         let response = await this.axios({
    //           method: "PUT",
    //           url: "/utilities/update-utility",
    //           data: {
    //             item: item.value,
    //             update: item.text,
    //             type: 'IMEF_GROUP'
    //           }
    //         });

    //         if (response.status == 200) {
    //           this.IMEF_GROUPS[index] = response.data.updated;
    //         }
    //       } catch (error) {
    //         console.log(error)
    //       } finally {
    //         this.groupEditing = null
    //         this.groupEditingIndex = -1
    //       }
    //     }
    // },
    // async removeGroup(item) {
    //   try {
    //         let response = await this.axios({
    //           method: "DELETE",
    //           url: "/utilities/delete-utility",
    //           data: {
    //             item: item.value,
    //             type: 'IMEF_GROUP'
    //           }
    //         });

    //         if (response.status == 200) {
    //           this.IMEF_GROUPS = response.data.newArray;
    //         }
    //       } catch (error) {
    //         console.log(error)
    //       } finally {
    //         this.groupEditing = null
    //         this.groupEditingIndex = -1
    //       }
    // },
    // filter(item, queryText, itemText) {
    //   if (item.header) return false;

    //   const hasValue = (val) => (val != null ? val : "");

    //   const text = hasValue(itemText).toString().toLowerCase();
    //   const query = hasValue(queryText).toString().toLowerCase();

    //   return text.includes(query);
    // },

    resetForm() {
      this.newMovementForm = {
        title: "",
        description: "",
        date: "",
        color: "#000",
        category: "",
        points: "",
        organizator: "",
        ceplan: "",
        eventType: "",
        contributionType: "",
        participation: "",
        publicationMedium: "",
        year: "",
        position: "",
        committeeType: "",
        topic: "",
      };
    },

    async resetFilters() {
      this.filters.splice(0, this.filters.length);
      this.filterMenu = false;
      await this.getMemberMovements();
    },

    async getMemberMovements() {
      this.loading();
      try {
        const response = await this.axios({
          method: "POST",
          url: `/movements/${this.member.id}`,
          data: {
            startDate: this.startDate,
            endDate: this.endDate,
            filters: this.filters,
          },
        });

        this.movements.splice(0, this.movements.length);

        this.movements = JSON.parse(JSON.stringify(response.data.movements));
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
        this.filterMenu = false;
        this.dateMenu = false;
      }
    },
    // endpoint to update
    async createMovement() {
      this.loading();
      try {
        const { id, headOffice = "" } = this.member;
        const response = await this.axios({
          method: "POST",
          url: `/movements/create/${id}/${headOffice}`,
          data: this.newMovementForm,
        });

        if (response.data) {
          this.show({
            text: "¡Se ha cargado el movimiento del miembro correctamente!",
            color: "success",
          });
          await this.getMemberMovements();
        }
      } catch (error) {
        console.log(error);
        this.show({
          text: "Hubo un error en la carga del movimiento, por favor intenta nuevamente",
          color: "error",
        });
      } finally {
        this.resetForm();
        this.newMovementDialog = false;
        this.loaded();
      }
    },

    monthName(month) {
      let months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      return months[month];
    },
  },

  computed: {
    formattedDateRange() {
      return `${this.formattedDate(this.startDate)} - ${this.formattedDate(
        this.endDate
      )}`;
    },

    truncatedName() {
      let name = this.member.name + " " + this.member.firstLastname;
      if (name.length > 25) {
        return name.substring(0, 25) + "...";
      }
      return name;
    },

    dateRange: {
      get() {
        // Return an array with the start and end date
        // dates must be in the format 'YYYY-MM-DD'

        let startDate =
          this.startDate.getFullYear() +
          "-" +
          (this.startDate.getMonth() + 1 < 10 ? "0" : "") +
          (this.startDate.getMonth() + 1) +
          "-" +
          (this.startDate.getDate() < 10 ? "0" : "") +
          this.startDate.getDate();

        let endDate =
          this.endDate.getFullYear() +
          "-" +
          (this.endDate.getMonth() + 1 < 10 ? "0" : "") +
          (this.endDate.getMonth() + 1) +
          "-" +
          (this.endDate.getDate() < 10 ? "0" : "") +
          this.endDate.getDate();

        return [startDate, endDate];
      },
      async set(value) {
        if (this.settedStartDate) {
          let endDateArray = value[0].split("-");
          this.endDate = new Date(
            endDateArray[0],
            endDateArray[1] - 1,
            endDateArray[2]
          );

          await this.getMemberMovements();
        } else {
          let startDateArray = value[0].split("-");
          this.startDate = new Date(
            startDateArray[0],
            startDateArray[1] - 1,
            startDateArray[2]
          );
        }

        this.settedStartDate = !this.settedStartDate;
      },
    },

    movementsColors() {
      let colors = [];

      for (let movement of this.movements) {
        if (!colors.includes(movement.color)) {
          colors.push(movement.color);
        }
      }

      //group in arrays of 3
      colors = colors.reduce((acc, curr, i) => {
        if (i % 3 === 0) acc.push([]);
        acc[acc.length - 1].push(curr);
        return acc;
      }, []);

      return colors;
    },

    groupedMovements() {
      let groupedMovements = [];

      if (this.movements.length == 0) return groupedMovements;

      for (let movement of this.movements) {
        let date = new Date(movement.date);
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear();

        let dayMovements = groupedMovements.find(
          (group) =>
            group.day == day && group.month == month && group.year == year
        );

        if (dayMovements) {
          dayMovements.movements.push(movement);

          if (!dayMovements.colors.includes(movement.color)) {
            dayMovements.colors.push(movement.color);
          }
        } else {
          groupedMovements.push({
            day,
            month,
            year,
            date,
            movements: [movement],
            colors: [movement.color],
          });
        }
      }

      //sort by date
      groupedMovements.sort((a, b) => b.date - a.date);

      return groupedMovements;
    },
  },

  watch: {
    // "newMovementForm.category": {
    //   async handler(newCategory) {
    //     this.setCategoryColor(newCategory);
    //     if (newCategory === "Evento") {
    //       await this.$_fetchImefUtilities();
    //     }
    //   },
    //   deep: true,
    // },

    dateMenu(value) {
      if (!value) {
        this.settedStartDate = false;
      }
    },

    async selectedMember() {
      if (this.selectedMember == this.member.id) {
        this.startDate = new Date();
        this.endDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 30);

        await this.getMemberMovements();
      }
    },
  },

  async created() {
    this.startDate.setDate(this.startDate.getDate() - 30);
    await this.getMemberMovements();
    await this.$_fetchImefUtilities();
  },
};
</script>

<style>
.dialog-card {
  height: 80vh;
  padding: 40px 64px;
  font-family: Montserrat, sans-serif;
}

.dialog-card-auto {
  height: auto;
  padding: 40px 64px;
  font-family: Montserrat, sans-serif;
}

.v-dialog {
  background-color: white;
  overflow-y: auto; /* Permitir desplazamiento vertical */
  overflow-x: hidden; /* Ocultar desplazamiento horizontal */
  -ms-overflow-style: none; /* IE y Edge */
  scrollbar-width: none; /* Firefox */
}

.v-dialog::-webkit-scrollbar {
  display: none; /* Ocultar scroll */
}

.member-name {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  text-transform: capitalize;
}

.date-range {
  font-size: 16px;
  font-weight: 700;
  color: #a5adb7;
}

.button-text {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  letter-spacing: normal;
  text-transform: capitalize;
}

.button {
  border-color: #c1c7cf;
}

.day-card {
  border-radius: 8px;
  border: 2px solid #ced7e3;
  background-color: #f8fafc;
}

.day-card-title {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.color-bar {
  width: 6px;
  height: 16px;
  border-radius: 4px;
}

.movements-counter {
  font-size: 12px;
  font-weight: 600;
  color: #000;
}

.expand-button {
  border: 1px solid #ced7e3 !important;
  border-color: #b6bec9 !important;
  border-radius: 4px;
  background-color: white;
}

.movement-card {
  border-radius: 8px;
  border: 2px solid #ced7e3;
  background-color: white;
}

.v-expansion-panel-content__wrap {
  padding: 8px 4px 4px 4px;
}

.big-color-bar {
  width: 6px;
  height: auto;
  border-radius: 4px;
}

.movement-title {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.movement-subtitle {
  font-size: 12px;
  font-weight: 700;
  color: #000;
}

.movement-description {
  font-size: 11px;
  font-weight: 500;
  color: #858484;
}

.movements-header {
  position: sticky !important;
}

.form-label {
  font-size: 16px;
  font-weight: 700;
  color: #000;
}

.color-outline {
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px;
}

.color-activator {
  width: 100%;
  height: 20px;
  border-radius: 4px;
  background-color: rgb(19, 19, 117);
}

.filter-button {
  text-transform: capitalize !important;
  letter-spacing: normal !important;
}

.filter-item {
  border-bottom: 1px solid #e0e0e0;
}
</style>